import Axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type CustomParamsSerializer
} from "axios";
import type { Router } from "vue-router";
import type {
  PureHttpError,
  RequestMethods,
  PureHttpResponse,
  PureHttpRequestConfig
} from "./types.d";
import { stringify } from "qs";
// import NProgress from "../progress";
import { getToken, formatToken } from "@/utils/auth";
import { useUserStoreHook } from "@/views/comm/login/store/user";
import { storageLocal } from "@/store/global-utils";
import { ElMessage } from "element-plus";

const ERROR_CODE_MAP: Record<number, string> = {
  // 基础系统错误
  404: "接口不存在",
  405: "不支持的请求类型",
  500: "服务端错误",

  // 系统级错误
  100001: "系统错误",
  100002: "参数错误",
  100003: "未授权",
  100004: "Token已过期",
  100005: "请先登录",
  100007: "服务繁忙",
  100008: "服务器不可用",
  100010: "地理位置错误",
  100011: "VPN已禁用",

  // 数据库操作错误
  100021: "查询错误",
  100022: "更新错误",
  100023: "插入错误",
  100024: "记录不存在",

  // 参数和租户错误
  100102: "参数不能为空",
  100202: "租户标识和租户ID和域名不能同时为空",
  100302: "租户信息未找到",
  100303: "租户信息不存在",
  100402: "租户库表错误",

  // 用户相关错误
  200001: "用户不存在",
  200002: "用户已存在",
  200003: "密码错误",
  200004: "验证码错误",
  200005: "账号错误",
  200006: "不支持的注册方式",
  200007: "账号状态异常",
  200008: "手机号重复",
  200009: "用户注册失败",
  200010: "用户名或密码错误",
  200011: "正在审核中",
  200012: "密码重复",
  200013: "邮箱已存在",
  200014: "CPF已存在",
  200015: "手机号已存在",
  200016: "重复认证",
  200017: "请求过多",

  // 租户和域名错误
  300003: "域名不能为空",
  300005: "租户ID必须为空",
  300006: "租户ID不能为空",
  300007: "租户标识不能为空",
  300106: "租户标识已存在",
  300108: "域名已存在",
  300109: "域名校验失败",
  300110: "域名查询失败",

  // 租户创建和更新错误
  300209: "创建租户失败",
  300210: "创建租户数据ID为空",
  300211: "创建租户ID为空",
  300212: "更新租户ID失败",
  300213: "更新租户信息失败",

  // 金额和等级错误
  400021: "金额不足",
  400010: "创建等级失败",

  // 分类相关错误
  500010: "创建分类失败",
  500011: "分类数据转换失败",
  500012: "分类列表错误",
  500013: "分类查询失败",
  500014: "删除失败",
  500015: "分类ID不能为空",
  500016: "分类更新失败",

  // 常见问题相关错误
  500020: "创建常见问题失败",
  500021: "常见问题列表错误",
  500022: "常见问题查询失败",
  500023: "删除失败",
  500024: "常见问题ID不能为空",
  500025: "常见问题更新失败",

  // 活动和转盘相关错误
  800001: "转盘旋转次数不足",
  800002: "金额已满足提现",
  800003: "未达到领取条件",
  800004: "未到领取时间",
  800005: "奖励已领取",

  // 活动特定错误
  850000: "活动不存在",
  850001: "活动进行中"
};

const API_BASE_CONFIG = JSON.parse(import.meta.env.VITE_API_BASE_URL);

const getBaseUrl = (url: string) => {
  if (JSON.parse(import.meta.env.VITE_APITYPE) <= 1) {
    return "/";
  }
  // 获取配置中所有的前缀路径
  const prefixes = Object.keys(API_BASE_CONFIG);

  // 查找匹配的前缀
  const matchedPrefix = prefixes.find(prefix => url.startsWith(prefix));

  // 如果找到匹配的前缀，返回对应的baseUrl，否则返回默认值
  if (matchedPrefix) {
    return API_BASE_CONFIG[matchedPrefix];
  }

  return "/";
};

const defaultConfig: AxiosRequestConfig = {
  // 请求超时时间
  baseURL: "/",
  timeout: 10000,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    "X-Device-Type": 1,
    "X-Device-Id": "fdfdfdfweq334",
    "X-Web-Terminal-Id": "WINDOWS",
    "X-Platform-Id": "B"
  },
  // 数组格式参数序列化
  paramsSerializer: {
    serialize: stringify as unknown as CustomParamsSerializer
  }
};

// 智能节流缓存，记录请求状态和时间
interface RequestCacheItem {
  timestamp: number;
  pending: boolean;
  promise?: Promise<any>;
}

export class PureHttp {
  private static router: Router;
  // 请求缓存，用于实现智能节流
  private static requestCache: Map<string, RequestCacheItem> = new Map();
  // 节流间隔时间（毫秒）
  private static throttleDelay: number = 1000;

  constructor() {
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }
  /** 设置路由实例 */
  public static setRouter(router: Router) {
    PureHttp.router = router;
  }
  /** `token`过期后，暂存待执行的请求 */
  private static requests = [];

  /** 防止重复刷新`token` */
  private static isRefreshing = false;

  /** 初始化配置对象 */
  private static initConfig: PureHttpRequestConfig = {};

  /** 保存当前`Axios`实例对象 */
  private static axiosInstance: AxiosInstance = Axios.create(defaultConfig);

  /** 生成请求缓存的唯一key */
  private static generateCacheKey(config: AxiosRequestConfig): string {
    const { method, url, params, data } = config;
    return `${method}_${url}_${JSON.stringify(params || {})}_${JSON.stringify(data || {})}`;
  }

  /** 智能节流处理 */
  private static handleThrottle(
    config: AxiosRequestConfig,
    execute: () => Promise<any>
  ): Promise<any> {
    const cacheKey = PureHttp.generateCacheKey(config);
    const now = Date.now();
    const cachedRequest = PureHttp.requestCache.get(cacheKey);

    // 如果存在缓存并且正在进行中，返回正在进行的Promise
    if (cachedRequest && cachedRequest.pending) {
      return cachedRequest.promise;
    }

    // 如果存在缓存且距离上次请求间隔小于节流时间
    if (
      cachedRequest &&
      now - cachedRequest.timestamp < PureHttp.throttleDelay
    ) {
      // 更新请求状态
      cachedRequest.pending = true;

      // 创建新的Promise
      const promise = new Promise((resolve, reject) => {
        setTimeout(
          () => {
            execute()
              .then(res => {
                resolve(res);
                PureHttp.requestCache.set(cacheKey, {
                  timestamp: Date.now(),
                  pending: false
                });
              })
              .catch(err => {
                reject(err);
                PureHttp.requestCache.set(cacheKey, {
                  timestamp: Date.now(),
                  pending: false
                });
              });
          },
          PureHttp.throttleDelay - (now - cachedRequest.timestamp)
        );
      });

      cachedRequest.promise = promise;
      return promise;
    }

    // 首次请求或超过节流时间，立即执行
    const promise = execute();
    PureHttp.requestCache.set(cacheKey, {
      timestamp: now,
      pending: true,
      promise
    });

    // 请求完成后更新缓存状态
    promise
      .then(() => {
        PureHttp.requestCache.set(cacheKey, {
          timestamp: Date.now(),
          pending: false
        });
      })
      .catch(() => {
        PureHttp.requestCache.set(cacheKey, {
          timestamp: Date.now(),
          pending: false
        });
      });

    return promise;
  }

  /** 重连原始请求 */
  private static retryOriginalRequest(config: PureHttpRequestConfig) {
    return new Promise(resolve => {
      PureHttp.requests.push((token: string) => {
        config.headers["Authorization"] = formatToken(token);
        resolve(config);
      });
    });
  }

  /** 请求拦截 */
  private httpInterceptorsRequest(): void {
    PureHttp.axiosInstance.interceptors.request.use(
      async (config: PureHttpRequestConfig): Promise<any> => {
        // 跳过设置 tenant-id 的接口白名单
        const skipTenantIdList = ["/client/tenant/get"];
        const shouldSkipTenantId = skipTenantIdList.some(url =>
          config.url.endsWith(url)
        );

        // 只在非白名单接口中设置 tenant-id
        if (!shouldSkipTenantId) {
          let currentTenantId: any =
            storageLocal().getItem<StorageConfigs>("tenantId");
          if (currentTenantId) {
            try {
              // 尝试解析JSON格式的tenantId
              if (
                typeof currentTenantId === "string" &&
                (currentTenantId.startsWith("{") ||
                  currentTenantId.startsWith("["))
              ) {
                currentTenantId = JSON.parse(currentTenantId);
              }
            } catch (e) {
              console.error("解析 tenantId 出错：", e);
              currentTenantId = null;
            }
          }
          if (currentTenantId) {
            config.headers["X-Tenant-Id"] = String(currentTenantId);
          }
        }

        // 优先判断post/get等方法是否传入回调
        if (typeof config.beforeRequestCallback === "function") {
          config.beforeRequestCallback(config);
          return config;
        }
        if (PureHttp.initConfig.beforeRequestCallback) {
          PureHttp.initConfig.beforeRequestCallback(config);
          return config;
        }

        /** 请求白名单，放置不需要token的接口 */
        const whiteList = ["/refresh-token", "/login"];
        const isWhiteList = whiteList.some(url => config.url.endsWith(url));

        // 如果是白名单接口，直接放行
        if (isWhiteList) {
          return config;
        }

        // 获取token
        const tokenData = getToken();
        if (!tokenData) {
          // 非白名单接口且没有token，重定向到登录
          const currentPath = window.location.href;
          if (!currentPath.includes("/login")) {
            PureHttp.router?.push({
              path: "/login",
              query: { redirect: currentPath } // 保存当前路径用于登录后跳回
            });
            return Promise.reject(new Error("Missing token"));
          }
          return config;
        }

        // 检查token是否过期
        const now = new Date().getTime();
        const expired = parseInt(String(tokenData.expireAt)) - now <= 0;

        if (expired) {
          // token已过期，需要刷新
          if (!PureHttp.isRefreshing) {
            PureHttp.isRefreshing = true;
            try {
              const res = await useUserStoreHook().handRefreshToken({
                refreshToken: tokenData.refreshToken
              });
              const newToken = res.data.token;
              // 设置新的token到请求头
              config.headers["Authorization"] = formatToken(newToken);
              // 处理队列中的请求
              PureHttp.requests.forEach(cb => cb(newToken));
              PureHttp.requests = [];
            } finally {
              PureHttp.isRefreshing = false;
            }
          }
          // 将请求添加到队列
          return PureHttp.retryOriginalRequest(config);
        } else {
          // token未过期，直接使用
          config.headers["Authorization"] = formatToken(tokenData.token);
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  /** 响应拦截 */
  private httpInterceptorsResponse(): void {
    const instance = PureHttp.axiosInstance;
    instance.interceptors.response.use(
      (response: PureHttpResponse) => {
        const $config = response.config;
        // 检查响应类型是否为Blob，如果是则直接返回而不检查code
        if (response.data instanceof Blob) {
          return response.data;
        }

        let code = response.data.code;

        // 无权限或需要登录的错误码处理
        if (code === 100005 || code === 100003) {
          localStorage.removeItem("user-info");
          PureHttp.router?.push("/login");
          return Promise.reject(new Error("token invalid"));
        }

        // 非成功状态码处理
        if (code !== 0) {
          console.warn("API 错误码：", code);
          // 优先使用接口返回的 msg
          const errorMessage =
            response.data.msg || ERROR_CODE_MAP[code] || "未知错误";
          ElMessage.error(errorMessage);
        }

        // 执行回调
        if (typeof $config.beforeResponseCallback === "function") {
          $config.beforeResponseCallback(response);
          return response.data;
        }
        if (PureHttp.initConfig.beforeResponseCallback) {
          PureHttp.initConfig.beforeResponseCallback(response);
          return response.data;
        }
        return response.data;
      },
      (error: PureHttpError) => {
        const $error = error;
        $error.isCancelRequest = Axios.isCancel($error);
        return Promise.reject($error);
      }
    );
  }

  /** 通用请求工具函数 */
  public request<T>(
    method: RequestMethods,
    url: string,
    param?: AxiosRequestConfig,
    axiosConfig?: PureHttpRequestConfig
  ): Promise<T> {
    if (param && param.data instanceof FormData) {
      param.headers = {
        ...param.headers,
        "Content-Type": "multipart/form-data"
      };
    }

    const config = {
      method,
      url,
      ...param,
      ...axiosConfig,
      baseURL: getBaseUrl(url)
    } as PureHttpRequestConfig;

    // 使用智能节流处理
    return PureHttp.handleThrottle(config, () => {
      return new Promise((resolve, reject) => {
        PureHttp.axiosInstance
          .request(config)
          .then((response: undefined) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  }

  /** 单独抽离的`post`工具函数 */
  public post<T, P>(
    url: string,
    params?: AxiosRequestConfig<P>,
    config?: PureHttpRequestConfig
  ): Promise<T> {
    return this.request<T>("post", url, params, config);
  }

  /** 单独抽离的`get`工具函数 */
  public get<T, P>(
    url: string,
    params?: AxiosRequestConfig<P>,
    config?: PureHttpRequestConfig
  ): Promise<T> {
    return this.request<T>("get", url, params, config);
  }

  /** 文件上传工具函数 */
  public uploadFile<T>(
    url: string,
    formData: FormData,
    config?: PureHttpRequestConfig
  ): Promise<T> {
    const uploadConfig: AxiosRequestConfig = {
      method: "post",
      url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        ...config?.headers
      }
    };

    return this.request<T>("post", url, uploadConfig, config);
  }
}

export const http = new PureHttp();
