interface Meta {
  icon?: string;
  title?: string;
  sort?: number;
  roles?: number[];
  keepAlive?: boolean;
}

interface Route {
  path: string;
  name?: string;
  meta?: Meta;
  children?: Route[];
}
export function convtRout(
  input: string,
  defaultKeepAlive: boolean = true
): Route[] {
  const parsedInput = JSON.parse(input.replace(/\\/g, ""));
  let nameCounter = 0;

  function generateName(): string {
    nameCounter += 1;
    return `route${nameCounter}`;
  }

  function transformRoute(route: any): Route {
    console.log(route.name, "---route-path-", route.path);
    console.log("---route--", route.name);
    const transformedRoute: Route = {
      path: route.path,
      name: route.name,
      meta: route.meta
        ? {
            ...route.meta,
            title: route.meta.menuName,
            roles: route.meta.roleId,
            keepAlive: defaultKeepAlive
          }
        : {
            keepAlive: defaultKeepAlive
          },
      children: route.children ? route.children.map(transformRoute) : undefined
    };

    if (route.menu_name && !transformedRoute.name) {
      transformedRoute.name = generateName();
    }

    return transformedRoute;
  }

  return parsedInput.map(transformRoute);
}
